$(document).ready(function () {
  var searchBtn = $(".search-btn");
  var searchBlock = $(".header-search-block");
  $(searchBtn).on('click', function () {
    $(searchBlock).fadeToggle(200);
    $(".site-head-search-input").focus();
  });
});
$(document).on("changeOffers", (e, offerSelected) => {
  let discountDom = $("div.products-view-label span.products-view-label-discount");
  if (discountDom.length > 0) {
    discountDom[0].innerHTML = offerSelected.Discount.Value + (offerSelected.Discount.Type == 0 ? "%" : "P");
    if (offerSelected.Discount.Value == 0) {
      discountDom.addClass("close");
    } else {
      discountDom.removeClass("close");
    }
  }
});