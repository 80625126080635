import '../styles/font.scss';
import '../styles/partials/breads.scss';
import '../styles/partials/gallery.scss';
import '../styles/partials/header.scss';
import '../styles/partials/menu-dropdown.scss';
import '../styles/partials/price.scss';
import '../styles/partials/toolbar-top.scss';
import '../styles/common/buttons.scss';
import '../styles/common/custom-input.scss';
import '../styles/partials/menu-general.scss';
import '../styles/partials/menu-header.scss';
import '../styles/partials/category.scss';
import '../styles/views/home.scss';
import '../styles/views/product.scss';
import '../styles/views/compareproducts.scss';
import '../styles/views/news.scss';
import '../styles/views/myAccount.scss';
import '../styles/views/user.scss';
import '../styles/general.scss';
import '../styles/views/brands.scss';
import '../styles/partials/products-specials.scss';
import '../styles/partials/product-categories.scss';
import '../styles/partials/footer.scss';
import '../styles/partials/news-block.scss';
import '../styles/partials/footer-menu.scss';
import '../styles/common/block.scss';
import '../styles/views/compareproducts.scss';
import '../scripts/_common/carousel/carousel.module.js';
import '../scripts/_partials/cart/cart.module.js';
import '../scripts/_partials/product-view/productView.decorator.js';
import '../scripts/_partials/shipping/shipping.module.js';
import '../scripts/_partials/subscribe/subscribe.module.js';
import '../scripts/_partials/catalog-filter/catalogFilter.module.js';
import '../scripts/_partials/sizes-viewer/sizesViewer.module.js';
import '../scripts/_partials/colors-viewer/colorsViewer.module.js';
import '../scripts/_common/tabs/tabs.module.js';
import '../scripts/_common/modal/modal.module.js';
import '../scripts/_common/scrollToTop/scrollToTop.module.js';
import '../scripts/toggle-search.js';
import '../scripts/extendScripts/menu-dropdown-custom.js';
import '../scripts/product/controllers/productController.js';