import './styles/product-view.scss';
export function runProductViewDecorator(moduleName) {
  angular.module("productView").config(['$provide', $provide => {
    $provide.decorator('productViewCarouselPhotosDirective', ['$delegate', 'domService', '$compile', '$timeout', ($delegate, domService, $compile, $timeout) => {
      const directive = $delegate[0];
      const originalLink = directive.link;
      const originalCompile = directive.compile;
      directive.compile = function (cElement, cAttrs) {
        return function newLinkFn(scope, element, attrs, ctrls) {
          originalLink.apply($delegate[0], arguments);
          const productViewCarouselPhotosCtrl = ctrls[1];
          const originEnter = productViewCarouselPhotosCtrl.process;
          ctrls[1].process = function () {
            const product = domService.closest(element[0], ".products-view-block");
            element[0].style.opacity = '0';
            $timeout(() => {
              const elChild = element[0].children[0];
              if (elChild !== undefined && product.parentElement.classList.contains('products-view-tile')) {
                const heightDifference = product.querySelector('.hover-show').getBoundingClientRect().bottom - product.children[0].getBoundingClientRect().bottom;
                elChild.style.marginBottom = "-" + heightDifference + "px";
              }
              element[0].style.opacity = '1';
            }, 100);
            return originEnter.apply(this, arguments);
          };
        };
      };
      return $delegate;
    }]);
  }]);
}
;