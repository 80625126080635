import { runProductViewDecorator } from './runnerProductViewDecorator.js';
try {
  angular.module("productView");
  runProductViewDecorator();
} catch (e) {
  angular.module("app").run(["$ocLazyLoad", function ($ocLazyLoad) {
    return import( /* webpackChunkName: "productViewZen" */
    /* webpackMode: "lazy" */
    './productViewZen.js').then(module => {
      $ocLazyLoad.inject(module.default).then(module => {});
    });
  }]);
}